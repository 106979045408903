.topicsContainer {
  height: 160px;
  display: flex;

  background: var(--bg-orange);
  color: white;

  display: flex;
  align-items: center;
}

.box {
  flex: 1;
  text-align: center;
  padding: 20px 40px;
}
