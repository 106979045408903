.carouselContainer {
  height: 52em;
  background-image: url('/bg-top.png');
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;

}

.carouselHead {
  padding: 5rem;
  text-align: center;

  font-size: 1.4rem;
}

.carouselHead h3 {
  margin-bottom: 1rem;
}

.searchForm {
  display: flex;
  border-radius: .3rem;
  overflow: hidden;
}

.searchInput {
  padding: 0 1rem;
  min-width: 30rem;
  height: 4rem;
  font-weight: bold;
  font-size: 1rem;
  border: 0;
}

.searchSubmit {
  height: 4rem;
  width: 10rem;
  padding: 1rem;
  font-size: 1rem;
  border: 0;
}
