.header {
  display: flex;
  align-items: center;
  height: 6em;
  border-width: 0 0 1px 0;
  padding: 1.5em 4.0em;
}

.logoContainer {
  flex: 1;
}

.list {
  
}

.list ul {
  list-style-type: none;
  display: flex;
}

.list ul li {
  width: 150px; /* TODO */
}

.list a {
  text-decoration: none;
  color: black;
  
}
