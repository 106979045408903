.topArray {
  height: 160px;
  display: flex;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  text-align: center;
  padding: 1rem 2rem;

  border: solid 1px black;
  width: 9rem;
  height: 9rem;
  border-radius: .3rem;
  font-size: .8rem;
}

.box h3 {
  font-size: 1rem;
}

.avg {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0 auto .5rem auto;
  width: 1.6rem;
  height: 1.6rem;
  font-size: 1.2rem;
  font-weight: bold;

  padding: .5rem;
  color: white;
  border-radius: .3rem;

  background: var(--bg-orange);
}
