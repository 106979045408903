.backgroundContent {
  visibility:hidden;
  opacity:0;
  transition: visibility 0.2s ease-in, opacity 0.2s ease-in;
  
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
}

.shadow {
  background: rgba(0, 0, 0, .4);
}

.visible {
  visibility: visible;
  opacity: 1;
}

.popupContainer {
  background: white;

  min-width: 36em;
  border: solid 1px #ababab;
  border-radius: .3em;
  padding: 1em;
}

.closeBar {
  display: flex;
}

.closeTitle {
  flex: 1;
}
