.form {
  margin-top: 10px;

  display: flex;
  flex-direction: column;
}

.form label {
  margin-bottom: 5px;
}

.form input {
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 3px;
  border: 1px solid #ababab;
}

.form input[type="submit"] {
  margin-bottom: 0px;
}

.form span {
  color: red;
  display: block;
  margin-bottom: 15px;
}
